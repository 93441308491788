import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialstate = {
    course:[],
    isCreated:null,
    single_course :[],
    isUpdated:{}
}

const courseSlice = createSlice({
name:'course',
initialState:initialstate ,
reducers:{
    addcourse(state,action){
        state.isCreated = action.payload
    },
    clearcoursecreated(state,action){
        state.isCreated = {}
    },
    getcourse(state,action){
        state.course= action.payload
    },
    getAcourse(state,action){
        state.single_course=action.payload
    },
    EditCourse(state,action){
        state.isUpdated = action.payload
    },
    clearUpdate(state,actio){
        state.isUpdated={}
    }

}
})

export const {addcourse,getcourse,clearcoursecreated,getAcourse,EditCourse,clearUpdate} = courseSlice.actions
export default courseSlice.reducer


export function addNewCourse (courseData){
    return async function addNewcourse (dispatch,getState) {
        const data = await axios.post(`${process.env.REACT_APP_BASE_URL}/course/addnew`,courseData)
        const result =data.data
    dispatch(addcourse(result))
        console.log(result);
    }
}
export function getAllCourses (){
    return async function getallcourse (dispatch,getState) {
        const data = await axios.get(`${process.env.REACT_APP_BASE_URL}/course/getallcourse`)
        const result =data.data
    dispatch(getcourse(result))
        console.log(result);
    }
}

export function getSingleCourse (id){
    return async function getsingleThunk (dispatch,getState){
        const data = await axios.get(`${process.env.REACT_APP_BASE_URL}/course/singlecourse/${id}`)
        const result =data.data
        dispatch(getAcourse(result))
        console.log(result);
    }
}

export function courseUpdate(id,courseDate){
    return async function updateThunk(dispatch,getState){
        const data = await axios.put(`${process.env.REACT_APP_BASE_URL}/course/updatecourse/${id}`,courseDate)
        const result = data.data
        dispatch(EditCourse(result))
        console.log(result);
    }
}