import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const initilstate ={
    isPay:{},
    studentActivity:{}
}
const extraActivitySlice = createSlice({
    name:'extraActivityPayment',
 initialState:initilstate,
 reducers:{
    addActivityPyment(state,action){
        state.isPay=action.payload
    },
    clearaddExtraActivityPayment(state,action){
        state.isPay={}
    },
    singleStudentActivity(state,action){
      state.studentActivity=action.payload
    }
 }
})

export const {addActivityPyment,clearaddExtraActivityPayment,singleStudentActivity} = extraActivitySlice.actions
export default extraActivitySlice.reducer

export function addNewActivityPayment (ActivityData){
    return async function addNewactivityThunk (dispatch,getState) {
        const data = await axios.post(`${process.env.REACT_APP_BASE_URL}/activitypayment/addnew`,ActivityData)
        const result =data.data
    dispatch(addActivityPyment(result))
        console.log(result);
    }
}

export function getStudentActiviy (id,month,year){
    return async function getactivityThunk (dispatch,getState) {
        const data = await axios.get(`${process.env.REACT_APP_BASE_URL}/activitypayment/getactivity/${id}/${month}/${year}`)
        const result =data.data
    dispatch(singleStudentActivity(result))
        console.log(result);
    }
}