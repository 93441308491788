import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { useDispatch,useSelector } from 'react-redux'
import { UserLogin } from "../action/UserAction";
import { loadUser } from "../action/UserAction";
import { LoginStaff } from "../slice/UserSlice2";

const Login = () => {
  const {user,isAuthenticated} = useSelector((state) =>state.userState)
  const {isLogin} = useSelector((state) => state.userState2)
  console.log(isLogin);
  
const dispatch = useDispatch()
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState();

 const [emailerror,setEmailerror] = useState()
 const [passworderror, setPassworderror] = useState()
  axios.defaults.withCredentials = true;
  let userData = {
    email,
    password
  }
  const validation= () =>{
   
    let valid = true 
    if(!email){
      setEmailerror("Email is required") 
      valid = false
    }
    if(!password){
      setPassworderror("Password is required")
      valid = false
    }
    
    return valid
  }

  const handleLogin = async () => {
    const isValid = validation()
    if(isValid){
      try {
        const userData = { email, password };
        await dispatch(LoginStaff(userData));
        
      } catch (error) {
        console.error("Login error:", error);
        setError("Failed to login. Please try again later.");
      }
    }
   
  };

  // const handleLogin = () => {
  
  //   console.log(userData);
  //   console.log(user);
  //   dispatch(UserLogin(userData))
  // };
  useEffect(() =>{
   
    if (isLogin.loginStatus === true) {
      navigate("/dashbord");
    } else if (isLogin.loginStatus === false) {
      setError("Incorrect Email or Password");
      Swal.fire({
        icon: "error",
        title: "Login Failed",
        text: error,
      });
    }
   

   },[isLogin])
  return (
    <div>
      <div
        className="bg-cover bg-no-repeat h-screen w-full relative overflow-hidden bg-bluecolor"
        // style={{
        //   backgroundImage: `url(https://png.pngtree.com/background/20210714/original/pngtree-original-design-business-office-management-system-background-banner-picture-image_1191085.jpg)`,
        // }}
      >
        <div className="w-96 h-96 absolute bg-white top-48 right-[35%] opacity-1 rounded-md border border-gray">
          <p className="text-center text-xl font-bold font-serif py-5">Login</p>
          <div className="flex flex-wrap  mx-3  mt-3">
            <div className="w-full px-3 pb-3">
              <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                Email
              </label>
              <input
                name=""
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className={`appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-blue`}
              />
               <p className="text-red-500 text-xs">{emailerror}</p>
            </div>
          </div>
          <div className="flex flex-wrap  mx-3  mt-3">
            <div className="w-full px-3 pb-3">
              <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                Password
              </label>
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className={`appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-blue`}
              />
              <p className="text-red-500 text-xs">{passworderror}</p>
            </div>
          </div>
          <div className="flex items-center justify-center px-5 mt-3">
            <button
              className="text-white font-serif font-semibold bg-bluecolor py-2 w-full rounded-md "
              onClick={(e) => handleLogin(e)}
            >
              Login
            </button>
          </div>
          {error && <p className="text-red-500 text-center font-semibold ">{error}</p>}
        </div>
      </div>
    </div>
  );
};

export default Login;
