import React from "react";

const Features = () => {
  return (
    <div className="md:h-[110vh] h-auto flex md:flex-row flex-col w-full py-10  ">
      <div className="md:w-1/2 w-full bg-primary md:px-20 px-10 md:py-8 py-4">
        <h1 className="text-white font-bold md:text-5xl text-2xl">
          Our Best Features
        </h1>
        <p className="text-gray-300 mt-5 md:text-xl text-lg">
          Special wedding garments are often worn, and the ceremony is sometimes
          followed by a wedding reception. Music, poetry.
        </p>
        <div className="mt-8 flex space-x-3">
          <div>
            <div className="bg-secondary rounded-full md:p-4 p-2  md:h-20 h-14 md:w-20 w-14">
              <img
                src="https://wpdemo.zcubethemes.com/qeducato/wp-content/uploads/2023/03/fea-icon01.png"
                className="h-full w-full object-cover"
              />
            </div>
          </div>
          <div>
            <h1 className="md:text-3xl font-bold text-white text-2xl">
              Skilled Teachers
            </h1>
            <p className="mt-3 text-gray-300 ">
              Special wedding garments are often worn, and the ceremony is
              sttimes followed by a wedding reception. Music, poetry, prayers,
              or readings from.
            </p>
          </div>
        </div>
        <div className="mt-8 flex space-x-3">
          <div>
            <div className="bg-secondary rounded-full md:p-4 p-2  md:h-20 h-14 md:w-20 w-14">
              <img
                src="https://wpdemo.zcubethemes.com/qeducato/wp-content/uploads/2023/03/fea-icon02.png"
                className="h-full w-full object-cover"
              />
            </div>
          </div>
          <div>
            <h1 className="md:text-3xl font-bold text-white text-2xl">
              Affordable Courses
            </h1>
            <p className="mt-3 text-gray-300 ">
              Special wedding garments are often worn, and the ceremony is
              sttimes followed by a wedding reception. Music, poetry, prayers,
              or readings from.
            </p>
          </div>
        </div>
        <div className="mt-8 flex space-x-3 ">
          <div>
            <div className="bg-secondary rounded-full md:p-4 p-2  md:h-20 h-14 md:w-20 w-14">
              <img
                src="https://wpdemo.zcubethemes.com/qeducato/wp-content/uploads/2023/03/fea-icon03.png"
                className="h-full w-full object-cover"
              />
            </div>
          </div>
          <div>
            <h1 className="md:text-3xl font-bold text-white text-2xl">
              Efficient & Flexible
            </h1>
            <p className="mt-3 text-gray-300 ">
              Special wedding garments are often worn, and the ceremony is
              sttimes followed by a wedding reception. Music, poetry, prayers,
              or readings from.
            </p>
          </div>
        </div>
      </div>
      <div className="md:w-1/2 w-full h-full">
        <img
          src="https://elearningindustry.com/wp-content/uploads/2021/06/5-fun-student-engagement-activities.png"
          className="h-full w-full object-cover"
        />
      </div>
    </div>
  );
};

export default Features;
