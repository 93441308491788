import React, { useState, useEffect, useRef } from "react";
import Sidebar from "../components/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import Topbar from "../components/Topbar";
import { getSingleXstudent } from "../slice/xpllorasthudent";
import {
  addPaymentDate,
  clearPayment,
  getOneStudent,
  getPaymetStudent,
} from "../slice/paymentSlice";
import store from "../store";
import { loadUser } from "../action/UserAction";
import Swal from "sweetalert2";
import Select from "react-select";

import { getAllExam } from "../slice/examSlice";
import {
  addNewExamPayment,
  clearExamPay,
  getExamSingleStudent,
} from "../slice/examPaymentSlice";
import course from "../slice/course";
import { useNavigate } from "react-router-dom";

const Payment = () => {
  const { paymentStudent, isPayed, singleStudent } = useSelector(
    (state) => state.paymentState
  );
  const { isPay, singleStudent_exam } = useSelector(
    (state) => state.examPayment
  );
  const { exam } = useSelector((state) => state.examState);
  console.log(exam);
  console.log(isPayed);
  console.log(paymentStudent);
  console.log(singleStudent);
  // console.log(singleStudent_exam);
  // const { user } = useSelector((state) => state.userState);
  // const staffId = user.result[0].staff_id;
  const {users} = useSelector((state) => state.userState2)
  console.log(users);
  const staffId = users.result[0].staff_id;
  const studentName = paymentStudent?.name;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // fetch the staff data
  useEffect(() => {
    store.dispatch(loadUser);
    console.log("singleuser", users.result);
  });
  useEffect(() => {
    dispatch(getAllExam());
  }, [dispatch]);

  // set the student Id
  const [studentId, setStudentId] = useState();
  const [notstudent, setNotstudent] = useState("");
  const [searchInitiated, setSearchInitiated] = useState(false);
  // fetch the single payment stuentDate
  const handleClick = () => {
    setError({ ...error, student_id: "" });
    console.log(studentId);
    setSearchInitiated(true);
    dispatch(getPaymetStudent(studentId));
   
   
  };
  useEffect(() => {
    if (searchInitiated) {
      if (paymentStudent ) {
        setNotstudent("");
      } else {
        setNotstudent("st not found");
      }
    }
  }, [paymentStudent, searchInitiated]);

  const month = [
    { value: 1, label: "Jan" },
    { value: 2, label: "Feb" },
    { value: 3, label: "March" },
    { value: 4, label: "Apr" },
    { value: 5, label: "May" },
    { value: 6, label: "Jun" },
    { value: 7, label: "Jul" },
    { value: 8, label: "Aug" },
    { value: 9, label: "Sep" },
    { value: 10, label: "Oct" },
    { value: 11, label: "Nov" },
    { value: 12, label: "Dec" },
  ];
  const generateYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = 0; i <= 5; i++) {
      years.push({
        value: currentYear - i,
        label: (currentYear - i).toString(),
      });
    }
    return years;
  };

  const yearOptions = generateYearOptions();

  const [selectedCourses, setSelectedCourses] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [courseInfo, setCourseInfo] = useState([]);
  const [pay_month, setPay_month] = useState([]);
  const [examfee, setExamfee] = useState(0);
  const [nettotal, setNettotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [error, setError] = useState({});
  const [coursebillInfo, setCoursebillInfo] = useState([]);
  const [coursebill, setCoursebill] = useState([]);
  // validation for studentID
  const validate = () => {
    let error = {};
    let isValid = true;

    if (!studentId) {
      error.student_id = "Please enter student Id";
      isValid = false;
    }

    setError(error);
    return isValid;
  };

  const studentidRef = useRef(null);
  useEffect(() => {
    if (error.student_id) {
      studentidRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [error]);

  const [courseamoutError, setCourseamoutError] = useState();
  const courseAmountValidate = () => {
    let valid = true;

    if (!nettotal) {
      setCourseamoutError("Select atleast one course");
      valid = false;
    }else{
      setCourseamoutError('')
    }
    return valid;
  };

  const handleCheckboxChange = (course, isChecked) => {
    let updatedCourses;
    let pay_course;
    let updatedCourseInfo;
    let courseamount;
    if (isChecked) {
      pay_course = [...courseInfo, course.courseId];
      updatedCourses = [...selectedCourses, course];
      updatedCourseInfo = [...coursebillInfo, course.name];
      courseamount = [...coursebill, course];
    } else {
      updatedCourses = selectedCourses.filter((c) => c.name !== course.name);
      pay_course = courseInfo.filter((id) => id !== course.courseId);
      updatedCourseInfo = coursebillInfo.filter((name) => name !== course.name);
      courseamount = coursebill.filter((id) => id !== course.courseId);
    }
    setSelectedCourses(updatedCourses);
    setCourseInfo(pay_course);
    setCoursebillInfo(updatedCourseInfo);
    setCoursebill(courseamount);
    const total = updatedCourses.reduce((sum, c) => sum + c.fee, 0);
    setTotalAmount(total);
    const subtotal = total + Number(examfee) - Number(discount); // Include exam fee and discount
    setNettotal(subtotal);
  };

  const courseBill = () => {
    return coursebill.reduce((total, course) => total + (course.fee || 0), 0);
  };
  const [montherror, setMontherror] = useState();
  const handleSelectMonth = (selectedoptn) => {
    setPay_month(selectedoptn);
    if (selectedoptn.length > 0) {
      setMontherror(""); // Clear the error when a month is selected
    }
  };
  const handleSelectYear = (selectedOption) => {
    setSelectedYear(selectedOption.value);
  };
  const handleSubmit = () => {
    const isValid = validate();
    const isCourseAmountValid = courseAmountValidate();
    const paymentDate = {
      studentId,
      staffId,
      totalAmount,
      courseInfo,
      pay_month: pay_month.map((option) => option.value),

      selectedYear,
    };
    if (isValid && isCourseAmountValid) {
      if (pay_month.length === 0) {
        setMontherror("Please select at least one month.");
      } else {
        console.log(paymentDate);
        dispatch(addPaymentDate(paymentDate));
        // Proceed with form submission logic
        console.log("Form submitted with selected months:", pay_month);
      }
    }
  };

  const paymentData = {
    studentName,
    coursebillInfo,
    bill: courseBill(),
    pay_month: pay_month.map((option) => option.label),

    selectedYear,
  };

  // course payment succes massage
  useEffect(() => {
    if (isPayed && isPayed.status !== undefined) {
      if (isPayed.status) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Course Fees Paid Successfuly!",
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch(clearPayment());
        navigate("/xpllorapaymentreceipt", { state: { paymentData } });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: '<a href="#">Why do I have this issue?</a>',
        });
      }
    }
  }, [isPayed]);

  // extra Activity
  const [selectedExams, setSelectedExams] = useState([]);
  const [examfeeInfo, setExamfeeInfo] = useState([]);
  const [exampay_month, setExampay_month] = useState([]);
  const [examselectYear, setExamselectYear] = useState(
    new Date().getFullYear()
  );
  const [updateExamFlag, setUpdateExamFlag] = useState(false);
  const [examInfo, setExamInfo] = useState([]);
  const [exambill, setExambill] = useState([]);
  const [examMonthError,setExamMonthError]=useState()
  // Handle checkbox change
  const handleExamCheckboxChange = (selectedExam, isChecked) => {
    let updateExam;
    let exam_pay;
    let exambillInfo;
    let exambillAmount;
    if (isChecked) {
      updateExam = [...selectedExams, selectedExam];
      exam_pay = [...examfeeInfo, selectedExam.exam_id];
      exambillInfo = [...examInfo, selectedExam.exam_name];
      exambillAmount = [...exambill, selectedExam];
    } else {
      updateExam = selectedExams.filter(
        (exam) => exam.exam_id !== selectedExam.exam_id
      );
      exam_pay = examfeeInfo.filter((id) => id !== selectedExam.exam_id);
      exambillInfo = examInfo.filter((name) => name !== selectedExam.exam_name);
      exambillAmount = exambill.filter(
        (exam) => exam.exam_id !== selectedExam.exam_id
      );
    }
    setSelectedExams(updateExam);
    setExamfeeInfo(exam_pay);
    setExamInfo(exambillInfo);
    setExambill(exambillAmount);
  };
  const billAmount = () => {
    return exambill.reduce((total, exam) => total + (exam.fees || 0), 0);
  };

  // Calculate total amount based on selected activities
  const calculateTotalAmount = () => {
    return selectedExams.reduce((total, exam) => (total += exam.fees), 0);
  };

  //select year for exam
  const handleExamSelectYear = (selectedOption) => {
    setExamselectYear(selectedOption.value);
  };
  //select months
  const handleExamSelectMonth = (selectedoptn) => {
    setExampay_month(selectedoptn);
    if(selectedoptn > 0){
      setExamMonthError('')
    }
  };
  const [updateCourseFlag, setUpdateCourseFlag] = useState(false);
  const handleMonth = (month) => {
    const isValid = validate();
    if (isValid) {
      dispatch(getOneStudent(studentId, month));
      setUpdateCourseFlag(true);

      dispatch(getExamSingleStudent(studentId, month));
      setUpdateExamFlag(true);
      console.log(singleStudent_exam);
    }
  };
  useEffect(() => {
    if (
      Array.isArray(singleStudent?.Result) &&
      singleStudent?.Result?.length > 0
    ) {
      const paidCourses = singleStudent.Result[0]?.course_id;
      const updatedCourses = paymentStudent.courses.filter((course) =>
        paidCourses.includes(course.courseId)
      );
      const pay_course = updatedCourses.map((c) => c.courseId);
      setSelectedCourses(updatedCourses);
      setCourseInfo(pay_course);
      setTotalAmount(updatedCourses.reduce((sum, c) => sum + c.fee, 0));
      setNettotal(
        updatedCourses.reduce((sum, c) => sum + c.fee, 0) + parseFloat(examfee)
      );
      setUpdateCourseFlag(false);
    }
  }, [singleStudent, course, updateCourseFlag]);

  useEffect(() => {
    if (
      updateExamFlag &&
      Array.isArray(singleStudent_exam.Result) &&
      singleStudent_exam.Result.length > 0
    ) {
      console.log(singleStudent_exam);
      const paidexam = singleStudent_exam.Result[0].examinfo;
      const updateExam = exam.filter((e) => paidexam.includes(e.exam_id));
      const exam_pay = updateExam.map((e) => e.exam_id);

      setExamfeeInfo(exam_pay);
      setSelectedExams(updateExam);
      setUpdateExamFlag(false);
    }
  }, [singleStudent_exam, exam, updateExamFlag]);

  const checkExamStatus = (examId) => {
    return examfeeInfo.includes(examId);
  };

  // validation  student id for exam fee
  const [examountError,setExamountError] = useState()
  const examAmountValidation =() =>{
    let valid = true;
    const total = calculateTotalAmount()
    if(!total){
      setExamountError('select tleast one exam')
      valid= false
    }
    return valid
  }

  // exam fess submit function
  const handleExamSubmit = () => {
    const isValidate = validate();
    const amountValid = examAmountValidation()
    if (isValidate && amountValid) {
      if(exampay_month.length === 0){
        setExamMonthError('Select atleast one month')
      }
      else{
        const examData = {
          studentId,
          staffId,
          examfeeInfo,
          totalAmount: calculateTotalAmount(),
          exampay_month: exampay_month.map((option) => option.value),
          examselectYear,
        };
        console.log(examData);
        dispatch(addNewExamPayment(examData));

      }
     
    }
  };
  const examData = {
    studentName,
    examInfo,
    bill: billAmount(),
    exampay_month: exampay_month.map((option) => option.label),
    examselectYear,
  };
  // exam fees payment success massage
  useEffect(() => {
    if (isPay && isPay.status !== undefined) {
      if (isPay.status) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Exam Fees Paid Successfuly!",
          showConfirmButton: false,
          timer: 1500,
        });
        dispatch(clearExamPay());
        navigate("/exampymentreceipt", { state: { examData } });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: '<a href="#">Why do I have this issue?</a>',
        });
      }
    }
  }, [isPay]);
  return (
    <div className=" h-screen p-3 w-full bg-slate-100">
      <div className="flex  p-2 w-full h-full space-x-5">
        <div className="w-1/5 h-full bg-bluecolor rounded-xl ">
          <Sidebar />
        </div>
        <div className=" w-4/5   ">
          <div className="px-3 bg-white rounded-md py-2">
            {/* <p className=' font-bold text-2xl text-graycolor'>Dashboard</p> */}
            <Topbar name="Xpllora Payment" />
          </div>

          <div className="  mt-5   h-[83vh] rounded-md overflow-auto  ">
            <div className=" h-full  rounded-md">
              {/* student search */}
              <div className="  mt-5  bg-white py-5 rounded-md mr-3">
                <div className="flex justify-center items-center space-x-3">
                  <p>Sudent ID:</p>
                  <input
                    ref={studentidRef}
                    name="student_id"
                    value={studentId}
                    onChange={(e) => setStudentId(e.target.value)}
                    className={`appearance-none block w-1/2 bg-gray-200  border border-gray-200 rounded py-3 px-4  leading-tight focus:outline-none focus:bg-white focus:border-bluecolor
             `}
                    type="text"
                    placeholder="student Id"
                  />
                  <button
                    className="bg-bluecolor px-10 py-3 rounded-md text-white "
                    onClick={() => handleClick()}
                  >
                    Search
                  </button>
                </div>
                <p className="text-red-500 text-xs text-center">
                  {error.student_id}
                </p>
                <p className="text-red-500 text-xs text-center">{notstudent}</p>
              </div>
              {/* student Details */}
              <div className=" mt-10 bg-white rounded-md mr-3">
                <div className="w-full bg-bluecolor text-white text-center font-semibold py-3 rounded-t-md ">
                  <p>Student Details</p>
                </div>
                <div className="p-3 flex items-center   flex-row w-full ">
                  <div className="w-1/2 flex flex-col space-y-2">
                    <p>
                      Student ID:
                      <span className="font-semibold px-2 mb-2">
                        {paymentStudent?.id}
                      </span>{" "}
                    </p>
                    <p>
                      Student Name:
                      <span className="font-semibold px-2">
                        {paymentStudent?.name}
                      </span>{" "}
                    </p>
                    <p>
                      paymentStudent NIC:
                      <span className="font-semibold px-2">
                        {paymentStudent?.nic}
                      </span>{" "}
                    </p>
                  </div>
                  <div className="flex flex-col space-y-2">
                    <p>
                      Mobile Number:
                      <span className="font-semibold px-2">
                        {paymentStudent?.contact_mobile}
                      </span>{" "}
                    </p>
                    <p>
                      paymentStudent Address:
                      <span className="font-semibold px-2">
                        {paymentStudent?.address}
                      </span>{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex mt-10 space-x-5 mr-3 h-auto">
                {/* Exam fess Details */}
                <div className="w-1/2 bg-white rounded-md ">
                  <div className="bg-bluecolor py-3  text-white font-medium rounded-t-md text-center">
                    <p>Exam Details </p>
                  </div>
                  <div className="px-8 mt-5 py-5">
                    {exam.map((a) => (
                      <div key={a.exam_name} className="flex justify-between ">
                        <input
                          className="text-2xl"
                          type="checkbox"
                          value={a.exam_id}
                          onChange={(e) =>
                            handleExamCheckboxChange(a, e.target.checked)
                          }
                          checked={checkExamStatus(a.exam_id)}
                        />
                        <span className="capitalize tracking-wide text-md font-semibold mb-2">
                          {a.exam_name}
                        </span>
                        <span className="font-semibold mb-2">{a.fees}</span>
                      </div>
                    ))}
                    <div className="mt-10 flex space-x-4">
                      <div className="w-1/2">
                        <Select
                          className="bg-gray-200  p-2 rounded-md"
                          options={yearOptions}
                          value={yearOptions.find(
                            (year) => year.value === selectedYear
                          )}
                          onChange={handleExamSelectYear}
                          closeMenuOnSelect={true}
                          hideSelectedOptions={true}
                        />
                      </div>
                      <div className="w-1/2">
                        <Select
                          name="exampay_month"
                          className="bg-gray-200 w-full p-2 rounded-md"
                          options={month}
                          value={exampay_month}
                          onChange={handleExamSelectMonth}
                          isMulti={true}
                          closeMenuOnSelect={false}
                          isSearchable={true}
                        />
                        {examMonthError && <p className="text-red-500 text-xs">{examMonthError}</p>}
                      </div>
                    </div>
                    <div className="flex mt-10">
                      <label className="w-1/2">Exam Fees Total:</label>
                      <input
                        type="text"
                        placeholder="RS."
                        value={`RS. ${calculateTotalAmount()}`}
                        className="w-1/2 bg-slate-200 p-2 appearance-none block rounded-md"
                      />

                    </div>
                    {examountError &&<p className="text-red-500 text-xs">{examountError}</p>}

                    <div className="flex h-10 mt-10 justify-end">
                      <button
                        className="bg-bluecolor h-full w-1/2 rounded-md text-white"
                        onClick={() => handleExamSubmit()}
                      >
                        Pay
                      </button>
                    </div>
                  </div>
                </div>
                {/* payment Details */}
                <div className="w-1/2 bg-white rounded-md h-auto ">
                  <div className="bg-bluecolor py-3  text-white font-medium rounded-t-md text-center">
                    <p>Payment Details</p>
                  </div>

                  <div className="px-8 mt-5 py-5">
                    {paymentStudent?.courses?.map((course) => (
                      <div key={course.name} className="flex justify-between">
                        <input
                          className="text-2xl"
                          type="checkbox"
                          value={course.name}
                          onChange={(e) =>
                            handleCheckboxChange(course, e.target.checked)
                          }
                          // if pay for this course
                          checked={selectedCourses.some(
                            (c) => c.courseId === course.courseId
                          )}
                        />
                        <span className="capitalize tracking-wide text-md font-semibold mb-2">
                          {course.name}
                        </span>
                        <span className="font-semibold mb-2">{course.fee}</span>
                      </div>
                    ))}
                    {/* <div className="flex mt-10">
                      <label className="w-1/2">Exam Fees:</label>
                      <input
                        type="text"
                        placeholder="RS."
                        value={examfee}
                        onChange={(e) => {
                          setExamfee(e.target.value);
                          const subtotal = totalAmount + Number(e.target.value) - Number(discount);
                          setNettotal(subtotal);
                        }}
            
                        className="w-1/2 bg-slate-200 p-2 appearance-none block rounded-md"
                      />
                    </div> */}
                    <div className="flex mt-5">
                      <label className="w-1/2"> Course Fee Total:</label>
                      <input
                        type="text"
                        placeholder="RS."
                        value={`RS. ${totalAmount}`}
                        className="w-1/2 bg-slate-200 p-2 appearance-none block rounded-md"
                      />
                    </div>
                    <div className="flex mt-5">
                      <label className="w-1/2">Discount:</label>
                      <input
                        type="text"
                        placeholder="RS."
                        value={discount}
                        onChange={(e) => {
                          setDiscount(e.target.value);
                          const subtotal =
                            totalAmount +
                            Number(examfee) -
                            Number(e.target.value);
                          setNettotal(subtotal);
                        }}
                        className="w-1/2 bg-slate-200 p-2 appearance-none block rounded-md"
                      />
                    </div>
                    <div className="flex mt-5">
                      <label className="w-1/2"> Total:</label>
                      <input
                        name="net_total"
                        type="text"
                        placeholder="RS."
                        value={`RS. ${nettotal}`}
                        className="w-1/2 bg-slate-200 p-2 appearance-none block rounded-md"
                      />
                    </div>
                    {courseamoutError && (
                      <p className="text-red-500 text-xs">{courseamoutError}</p>
                    )}
                    <div className="flex space-x-4 mt-10">
                      <div className="w-3/4">
                        <Select
                          className="bg-gray-200  p-2 rounded-md"
                          name="pay_month"
                          options={month}
                          value={pay_month}
                          onChange={handleSelectMonth}
                          isMulti={true}
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                        />
                        {montherror && (
                          <p className="text-red-500 text-xs">{montherror}</p>
                        )}
                      </div>

                      <Select
                        className="bg-gray-200 w-1/4 p-2 rounded-md"
                        options={yearOptions}
                        value={yearOptions.find(
                          (year) => year.value === selectedYear
                        )}
                        onChange={handleSelectYear}
                        closeMenuOnSelect={true}
                        hideSelectedOptions={true}
                      />
                    </div>
                    <div className="mt-10 h-10 flex justify-end">
                      <button
                        className="bg-bluecolor w-1/2 h-full rounded-md text-white"
                        onClick={() => handleSubmit()}
                      >
                        Pay
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/* Month details */}
              <div className=" mr-3  mt-10">
                <div className="grid grid-cols-12 text-white  gap-2">
                  {month.map((m) => (
                    <button
                      className="bg-bluecolor rounded-md py-2  "
                      onClick={() => handleMonth(m.value)}
                    >
                      {m.label}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;
