import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const initialstate ={
    isCreated:{},
    allgrades:[],
    single_grade:[],
    isUpadate:{}
}

const preschoolcourseSlice = createSlice({
    name: "preschoolcourse",
    initialState: initialstate,
    reducers:{
        addGrade(state,action){
            state.isCreated=action.payload
        },
        clearGradeCreated(state,action){
            state.isCreated={}
        },
        getAllGrades(state,action){
            state.allgrades=action.payload
        },
        getSingleGrade(state,action){
            state.single_grade=action.payload
        },
        updateGrade(state,action){
            state.isUpadate=action.payload
        },
        clearUpdate(state,action){
            state.isUpadate={}
        }
    }
})

export const  {addGrade,clearGradeCreated,getAllGrades,getSingleGrade,updateGrade,clearUpdate} = preschoolcourseSlice.actions
export default preschoolcourseSlice.reducer


export function addPreschollGrade(gradeData){
    return async function addcourseThunk(dispatch,getState){
        const data = await axios.post(`${process.env.REACT_APP_BASE_URL}/preschool/addnewgrade`,gradeData)
        const result = data.data
        dispatch(addGrade(result))
        console.log(result);
    }
}


export function getPreschollGrade(){
    return async function getcourseThunk(dispatch,getState){
        const data = await axios.get(`${process.env.REACT_APP_BASE_URL}/preschool/getallgrade`)
        const result = data.data
        dispatch(getAllGrades(result))
        console.log(result);
    }
}

export function getSinglePresclGrade(id){
    return async function getAcourseThunk(dispatch,getState){
        const data = await axios.get(`${process.env.REACT_APP_BASE_URL}/preschool/getagrde/${id}`)
        const result = data.data
       dispatch(getSingleGrade(result))
        console.log(result);
    }
}

export function UpdatePresclGrde(id,gradeData) {
    return async function updatecourseThunk(dispatch, getState) {
        const data = await axios.put(`${process.env.REACT_APP_BASE_URL}/preschool/updategrade/${id}`,gradeData)
        const result = data.data
        dispatch(updateGrade(result))
        console.log(result);

    }
}