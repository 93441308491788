import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import Login from "../pages/Login";
import Dashboard from "../pages/Dashboard";
import Addstudent from "../pages/pre_school/Addstudent";
import Allstudents from "../pages/pre_school/Allstudents";
import AddnewStudent from "../pages/xpllora/AddnewStudent";
import Newstaff from "../staff/Newstaff";
import Allstaff from "../staff/Allstaff";
import Topbar from "../components/Topbar";
import Updatestaff from "../staff/Updatestaff";
import Updatestudent from "../pages/pre_school/Updatestudent";
import AllStudent from "../pages/xpllora/AllStudent";
import Updatexstudent from "../pages/xpllora/Updatexstudent";
import Allcourses from "../course/Allcourses";
import Parent from "../pages/pre_school/Parent";
import SInglestudent from "../pages/pre_school/SInglestudent";
import Addcourse from "../course/Addcourse";
import Payment from "../payment/Payment";
import Updatecourse from "../course/Updatecourse";
import Addprescholl from "../pre_school_course/Addprescholl";
import Preschoolpayment from "../payment/Preschoolpayment";
import Allprestudentcourse from "../pre_school_course/Allprestudentcourse";
import Updatepresclcourse from "../pre_school_course/Updatepresclcourse";
import Home from "../userUI/pages/Home";
import Main from "../userUI/pages/Main";
import Header from "../userUI/components/Header";
import Aboutus from "../userUI/pages/Aboutus";
import Addadamecstaff from "../academicstaff/Addadamecstaff";
import Allacademicstaff from "../academicstaff/Allacademicstaff";
import Updateacademicstaff from "../academicstaff/Updateacademicstaff";
import Addextraactivity from "../pre_school_course/Addextraactivity";
import Updateextraactivity from "../pre_school_course/Updateextraactivity";
import Addexam from "../course/Addexam";
import Updateexam from "../course/Updateexam";
import Paymentreceipt from "../payment/Paymentreceipt";
import Extaactivityreceipt from "../payment/Extaactivityreceipt";
import Xpllorapytmrecipt from "../payment/Xpllorapytmrecipt";
import Examreceipt from "../payment/Examreceipt";
import Aboutuspage from "../userUI/pages/Aboutuspage";
import Coursepage from "../userUI/pages/Coursepage";
import Gallery from "../userUI/pages/Gallery";
import COntactus from "../userUI/pages/COntactus";
import Privateroute from "./Privateroute";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <Main />,
      },

      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/dashbord",
        element: (
          <Privateroute>
            <Dashboard />
          </Privateroute>
        ),
      },
      {
        path: "/addstudent",
        element: <Addstudent />,
      },
      {
        path: "/allstudent",
        element: <Allstudents />,
      },
      {
        path: "/addxpllora",
        element: <AddnewStudent />,
      },
      {
        path: "/addNewstaff",
        element: <Newstaff />,
      },
      {
        path: "/allStaff",
        element: <Allstaff />,
      },
      {
        path: "/updatestaff/:id",
        element: <Updatestaff />,
      },
      {
        path: "/topbar",
        element: <Topbar />,
      },
      {
        path: "/updatePrestudent/:id",
        element: <Updatestudent />,
      },
      {
        path: "/allxstudent",
        element: <AllStudent />,
      },
      {
        path: "updatestudent/:id",
        element: <Updatexstudent />,
      },
      {
        path: "/courses",
        element: <Allcourses />,
      },
      {
        path: "/parents",
        element: <Parent />,
      },
      {
        path: "/singleprestudent/:id",
        element: <SInglestudent />,
      },
      {
        path: "/addcourses",
        element: <Addcourse />,
      },
      {
        path: "/xpllorapayment",
        element: <Payment />,
      },
      {
        path: "/preschoolpayment",
        element: <Preschoolpayment />,
      },
      {
        path: "/courseupdate/:id",
        element: <Updatecourse />,
      },
      {
        path: "/addpreschool",
        element: <Addprescholl />,
      },
      {
        path: "/allpreschollcourse",
        element: <Allprestudentcourse />,
      },
      {
        path: "/updatepresclcourse/:id",
        element: <Updatepresclcourse />,
      },
      {
        path: "/addacademicstaff",
        element: <Addadamecstaff />,
      },
      {
        path: "/allacademicstaff",
        element: <Allacademicstaff />,
      },
      {
        path: "/editacdemicstaff/:id",
        element: <Updateacademicstaff />,
      },
      {
        path: "/extraactiviy",
        element: <Addextraactivity />,
      },
      {
        path: "/updateactivity/:id",
        element: <Updateextraactivity />,
      },
      {
        path: "/addexam",
        element: <Addexam />,
      },
      {
        path: "/updateexam/:id",
        element: <Updateexam />,
      },
      {
        path: "/paymentreceipt",
        element: <Paymentreceipt />,
      },
      {
        path: "/extraactivityreceipt",
        element: <Extaactivityreceipt />,
      },
      {
        path: "/xpllorapaymentreceipt",
        element: <Xpllorapytmrecipt />,
      },
      {
        path: "/exampymentreceipt",
        element: <Examreceipt />,
      },
      {
        path: "/aboutus",
        element: <Aboutuspage />,
      },
      {
        path: "/coursepage",
        element: <Coursepage />,
      },
      {
        path: "/gallery",
        element: <Gallery />,
      },
      {
        path: "/cotctus",
        element: <COntactus />,
      },
    ],
  },
]);
export default router;
