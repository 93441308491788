import React, { useState,useEffect } from "react";
import Sidebar from "../components/Sidebar";
import { MdDelete } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import Topbar from "../components/Topbar";
import { Link, useNavigate } from "react-router-dom";
import { getAllCourses, getSingleCourse } from "../slice/course";
import { getAllExam } from "../slice/examSlice";

const Allcourses = () => {
  const {single_course, course } = useSelector((state) => state.courseState);
  const { exam  } = useSelector(state => state.examState)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // fetch the all courses
  useEffect(() => {
    dispatch(getAllCourses());
    console.log(course);
  }, [dispatch]);
 
  //fetch the all exam
  useEffect(() =>{
    dispatch(getAllExam())
    console.log(exam);
  },[dispatch])

// get the course levels
  const renderGradeLevels = (gradeLevels) => {
    try {
      const levels = JSON.parse(gradeLevels);
      if (Array.isArray(levels)) {
        return (
          <ul className="">
            {levels.map((level, index) => (
              <li key={index}>{level.grade_level}</li>
            ))}
          </ul>
        );
      }
      return <li>No levels available</li>;
    } catch (error) {
      console.error("Invalid JSON in gradeLevels:", gradeLevels);
      return <li>Invalid levels data</li>;
    }
  };

  const handleUpdate =(id)=>{
 console.log(id);
 navigate(`/courseupdate/${id}`)
  }
  const handleExamUpdate =(id)=>{
    navigate(`/updateexam/${id}`)
  }
  return (
    <div className=" h-screen p-3 w-full bg-slate-100">
      <div className="flex  p-2 w-full h-full space-x-5">
        <div className="w-1/5 h-full bg-bluecolor rounded-xl ">
          <Sidebar />
        </div>
        <div className=" w-4/5   ">
          <div className="px-3 bg-white rounded-md py-2">
            {/* <p className=' font-bold text-2xl text-graycolor'>Dashboard</p> */}
            <Topbar name="All Courses" />
          </div>

          <div className="  mt-5  p-3 h-[83vh] rounded-md  ">
            {/* all xpllora courses */}
            <div className=" h-1/2  flex space-x-5 overflow-auto">
              <div className="w-full bg-white px-3 rounded-md  overflow-auto h-full  ">
                <Link to="/addcourses">
                  <button className="mt-5 ml-10 bg-bluecolor px-10 py-1 rounded-md text-white">
                    ADD COURSES
                  </button>
                </Link>{" "}
                <div className="border-b-2 border-gray-200 mt-5"></div>
                <div>
                <table className="mt-8 w-full text-left  ">
        <thead>
          <tr className="text-graycolor">
            <th className="px-3 py-3">Course Name</th>
            <th className="px-3 py-3">Description</th>
            <th className="px-3 py-3">Fees</th>
            {/* <th className="px-3 py-3">Levels</th> */}
            <th className="px-3 py-3">Action</th>
          </tr>
        </thead>
        <tbody className="  ">
          {course.map((c) => (
            <tr  className={`mt-3 border-b-[1px] border-b-gray-100 text-bluecolor `}
         >
              <td className="px-3 py-4 ">{c.name}</td>
             <td className="px-3 py-4 ">
            {c.description}
             
              </td>
             <td className="px-3 py-4 ">{c.fees}</td>
             {/* <td className="px-3 py-4 ">
             {c.grade_levels
                            ? renderGradeLevels(c.grade_levels)
                            : "No levels available"}
             </td> */}
             <td className="px-3 py-4 flex space-x-2 items-center">
             {/* <button className="font-medium text-xl text-green-600 dark:text-green-500 hover:underline"
        onClick={() => handleView(student.id)}>
            <GrView/>
          </button> */}
          <button
            className="font-medium text-lg text-blue-400  hover:text-blue-500"
           onClick={() => handleUpdate(c.id)}
          >
            <FaEdit />
          </button>
          <button className="font-medium text-lg text-red-400 hover:text-red-500">
            <MdDelete />
          </button>
             </td>
          
            </tr>

          ))}
         
        </tbody>
      </table>
      </div>
              </div>
             
            </div>
            {/* all xpllora exams */}
            <div className=" h-1/2 overflow-auto mt-10 flex space-x-5">
              <div className="w-full bg-white px-3 rounded-md  overflow-auto h-full  ">
                <Link to="/addexam">
                  <button className="mt-5 ml-10 bg-bluecolor px-10 py-1 rounded-md text-white">
                    ADD EXAM
                  </button>
                </Link>{" "}
                <div className="border-b-2 border-gray-200 mt-5"></div>
                <div>
                <table className="mt-8 w-full text-left  ">
        <thead>
          <tr className="text-graycolor">
            <th className="px-3 py-3">Exam Name</th>
            <th className="px-3 py-3">Description</th>
            <th className="px-3 py-3">Fees</th>
            {/* <th className="px-3 py-3">Levels</th> */}
            <th className="px-3 py-3">Action</th>
          </tr>
        </thead>
        <tbody className="  ">
          {exam.map((c) => (
            <tr  className={`mt-3 border-b-[1px] border-b-gray-100 text-bluecolor `}
         >
              <td className="px-3 py-4 ">{c.exam_name}</td>
             <td className="px-3 py-4 ">
            {c.exam_description}
             
              </td>
             <td className="px-3 py-4 ">{c.fees}</td>
             {/* <td className="px-3 py-4 ">
             {c.grade_levels
                            ? renderGradeLevels(c.grade_levels)
                            : "No levels available"}
             </td> */}
             <td className="px-3 py-4 flex space-x-2 items-center">
             {/* <button className="font-medium text-xl text-green-600 dark:text-green-500 hover:underline"
        onClick={() => handleView(student.id)}>
            <GrView/>
          </button> */}
          <button
            className="font-medium text-lg text-blue-400  hover:text-blue-500"
           onClick={() => handleExamUpdate(c.exam_id)}
          >
            <FaEdit />
          </button>
          <button className="font-medium text-lg text-red-400 hover:text-red-500">
            <MdDelete />
          </button>
             </td>
          
            </tr>

          ))}
         
        </tbody>
      </table>
      </div>
              </div>
             
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Allcourses;
