import { createSlice } from "@reduxjs/toolkit"
import axios from "axios"

const iitialstate={
    paymentStudent:null,
    isPayed:{}
}

const presclpaymentSlice= createSlice({
    name:"presclpayment",
    initialState:iitialstate,
    reducers:{
        getStudent(state,action){
            state.paymentStudent=action.payload

        },
        addPrePayment(state,action){
            state.isPayed=action.payload
        },
        clearPrePayment(state,action){
            state.isPayed={}
        }
    }
})

export const {getStudent,addPrePayment,clearPrePayment} = presclpaymentSlice.actions
export default presclpaymentSlice.reducer

export function getPreSclPymentStd(id){
    return async function getStudentTunk(dispatch,getState){
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/prepayment/getstudent/${id}`)
        const result = response.data
        dispatch(getStudent(result))
        console.log(result);
    }
}

export function addPreSclPymentStd(paymentData){
    return async function addStudentTunk(dispatch,getState){
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/prepayment/addprepayment`,paymentData)
        const result = response.data
        dispatch(addPrePayment(result))
        console.log(result);
    }
}