import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const loadUserFromStorage = () => {
    const users = localStorage.getItem("user");
    return users ? JSON.parse(users) : null;
  };
const initialstate ={
    isLogin:{},
    users:loadUserFromStorage() || {},
}
//Function to load user data from local storage

 const userSlice = createSlice({
    name:'useraction',
    initialState:initialstate,
    reducers:{
        login(state,action){
            state.isLogin =action.payload
            state.users = action.payload
            localStorage.setItem("user", JSON.stringify(action.payload));
           
        },
        getSingleUser(state,action){
          state.users = action.payload
          
        },
        logout(state) {
         
          state.users = {};
          localStorage.removeItem("user");
        }
    }
 })

 export const {login,getSingleUser, logout} = userSlice.actions
 export default userSlice.reducer

 export  function  LoginStaff (LoginData) {
    return async function loginfThunk(dispatch, getState)  {
        const data = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/adminlogin`,LoginData)
        const result =data.data
      dispatch(login(result))
        console.log(result);

 }
}

export  function  getUser () {
  return async function loginfThunk(dispatch, getState)  {
      const data = await axios.get(`${process.env.REACT_APP_BASE_URL}/auth/singleUser`)
      const result =data.data
    dispatch(getSingleUser(result))
      console.log(result);

}
}

// export  function logoutUser () {
//   return async function loginfThunk(dispatch, getState)  {
//       const data = await axios.get(`http://localhost:4000/auth/singleUser`)
//       const result =data.data
//     dispatch(getSingleUser(result))
//       console.log(result);

// }
// }