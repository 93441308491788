import { createSlice } from "@reduxjs/toolkit";

const staffSlice = createSlice({
  name: "Staff_Slice",
  initialState: {
    staff: {},
    loading: false,
    isStaffCreate: false,
    isAuthendicate:false,
    allStaff:[]
  },
  reducers: {
    addStaffRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    addStaffSuccess(state, action) {
      return {
        
        loading: false,
        staff: action.payload,
        isStaffCreate: true,
      };
    },
    addStaffFail(state, action) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        isStaffCreate: false,
      };
    },
   
    getAllStaffRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    getAllStafSuccess(state, action) {
      return {
        ...state,
        loading: false,
        allStaff: action.payload,
      };
    },
    getAllStaffFail(state, action) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    removeStaff(state,action){
      return{
        ...state,
        loading:true,

      }
    },
    removeStaffSuccuess(state,action){
      
      return{
        ...state,
      
        allStaff: state.allStaff.filter(staff => staff.staff_id !== action.payload),
        loading:false
      }
    },
    removeStaffFail(state,action){
      return{
           ...state,
           loading:false,
           error:action.payload
      }
    },
    updateStaffRequest(state,action){
      return{
        ...state,
        loading:true
      }
    },
    updateStaffSucess(state,action){
      return{
        ...state,
        loading:false,
        allStaff:action.payload
      }
    },
    updateStaffFail(state,action){
      return{
        ...state,
        loading:false,
        error:action.payload
      }
    },
    getSingleStaffRequest(state,action){
      return{
        ...state,
        loading:true
      }
    },
    getSingleStaffSuccess(state,action){
      return{
        loading:false,
        staff:action.payload
      }
    },
    getSingleStaffFail(state,action){
      return{
        loading:false,
        error:action.payload
      }
    }
  },
});

const { actions, reducer } = staffSlice;
export const {
  addStaffRequest,
  addStaffSuccess,
  addStaffFail,
  getAllStaffRequest,
  getAllStaffFail,
  getAllStafSuccess,

  removeStaff,
  removeStaffSuccuess,
  removeStaffFail,
  updateStaffRequest,
  updateStaffSucess,
  updateStaffFail,
  getSingleStaffRequest,
  getSingleStaffSuccess,
  getSingleStaffFail

} = actions;
export default reducer;
