
import {
  addStaffFail,
  addStaffRequest,
  addStaffSuccess,
  getAllStafSuccess,
  getAllStaffFail,
  getAllStaffRequest,

  getSingleStaffFail,

  getSingleStaffRequest,

  getSingleStaffSuccess,

  removeStaff,
  removeStaffFail,
  removeStaffSuccuess,
} from "../slice/staffSlice";
import axios from "axios";


export const addStaff = (staff) => async (dispatch) => {
 
    try {
        dispatch(addStaffRequest());
        const data = await axios.post("${process.env.REACT_APP_BASE_URL}/auth/newstaff", staff)
     
          dispatch(addStaffSuccess( data.data))
        
    } catch (error) {
        dispatch(addStaffFail(error))
    }

 
};


export const getAllStaff =() =>async (dispatch) =>{
  try {
    dispatch(getAllStaffRequest())
    const result =await axios.get('${process.env.REACT_APP_BASE_URL}/auth/allstaff')
    dispatch(getAllStafSuccess(result.data))
    
  } catch (error) {
    dispatch(getAllStaffFail(error))
  }
}

export const getSingleStaff = (id) => async(dispatch) =>{
  try {
    dispatch(getSingleStaffRequest())
    const result = await axios.get(`${process.env.REACT_APP_BASE_URL}/auth/singlestaff/${id}`)
    dispatch(getSingleStaffSuccess(result.data))
  } catch (error) {
    dispatch(getSingleStaffFail(error))
  }
}


export const RemoveStaff = id => async(dispatch) =>{
  console.log(id);
  
    try {
      dispatch(removeStaff());
      const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/auth/remove/${id}`);
      
      dispatch(removeStaffSuccuess(response.data));
    } catch (error) {
      dispatch(removeStaffFail(error));
    }
}

