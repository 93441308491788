import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { IoPersonAdd } from "react-icons/io5";
import { ImUsers } from "react-icons/im";
import { MdLibraryAdd } from "react-icons/md";
import { MdLibraryAddCheck } from "react-icons/md";
import { MdPayments } from "react-icons/md";
import { RiParentFill } from "react-icons/ri";
import { MdDashboard } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { LuLogOut } from "react-icons/lu";
import { getAllStaff } from "../action/StaffAction";
import { MdKeyboardArrowDown } from "react-icons/md";
import { MdKeyboardArrowUp } from "react-icons/md";
import { IoSchoolOutline } from "react-icons/io5";
import axios from "axios";
import { logOut } from "../action/UserAction";
import { MdOutlinePayment } from "react-icons/md";
import { logout } from "../slice/UserSlice2";
import logo from "../image/logo.png";
const Sidebar = () => {
  // const { isAuthenticated, user } = useSelector((state) => state.authState);
  // console.log(user);
  // const { user } = useSelector((state) => state.userState);
  const { users } = useSelector((state) => state.userState2);
  console.log(users);
  console.log(users.result[0]);
  const role = users.result[0].role;
  console.log(role);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  axios.defaults.withCredentials = true;
  // useEffect(() =>{
  // dispatch(getAllStaff())
  // },[dispatch])

  const [preDrop, setPreDrop] = useState(false);
  const toggleDropPre = () => {
    setPreDrop(!preDrop);
  };

  const [xplloraDrop, setXplloraDrop] = useState(false);
  const toggleDropXpllora = () => {
    setXplloraDrop(!xplloraDrop);
  };

  const [staffDrop, setStafDrop] = useState(false);
  const toggleStaff = () => {
    setStafDrop(!staffDrop);
  };
  const [acaemicstaff, setAcaemicstaff] = useState(false);
  const toggleAcaemicstaff = () => {
    setAcaemicstaff(!acaemicstaff);
  };
  const [courseDrop, setCourseDrop] = useState(false);
  const toggleCourse = () => {
    setCourseDrop(!courseDrop);
  };
  const handleLogout = () => {
    // dispatch(logOut())
    navigate("/");
    dispatch(logout());
  };
  return (
    <div className="">
      <div className="border-b-2 border-b-graycolor  text-center text-white text-lg flex items-center justify-center h-32 ">
        <img src={logo} className="h-full w-full object-cover" />
      </div>
      <ul className="px-2 uppercase flex flex-col space-y-5 mt-5">
        <Link to={`/dashbord`}>
          <li className=" px-2  py-3 text-white  hover:bg-white hover:text-bluecolor hover:rounded-md w-full mr-2 flex space-x-5 items-center">
            <MdDashboard className="text-lg" />
            <p>DASHBOARD</p>
          </li>
        </Link>
        <li className=" px-2 py-3 text-white  mr-2">
          <button
            className="uppercase flex space-x-5 items-center"
            onClick={toggleDropPre}
          >
            <IoPersonAdd className="text-lg" />
            <p>Pre Student</p>
            {preDrop ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}{" "}
          </button>
          {preDrop && (
            <div className="absolutr left-2 top-3 ">
              <ul className=" ml-5 mt-5 flex flex-col space-y-5">
                <li className="  hover:bg-white hover:text-bluecolor hover:rounded-md w-full py-2">
                  {" "}
                  <Link
                    to="/addstudent"
                    className="flex space-x-5 items-center px-2"
                  >
                    <IoPersonAdd className="text-lg" /> <p> ADD STUDENT</p>
                  </Link>
                </li>
                <li className="  hover:bg-white hover:text-bluecolor hover:rounded-md w-full py-2">
                  {" "}
                  <Link
                    to="/allstudent"
                    className="flex space-x-5 items-center  px-2"
                  >
                    {" "}
                    <ImUsers className="text-lg" />
                    <p> ALL STUDENTS</p>
                  </Link>
                </li>
                <li className="  hover:bg-white hover:text-bluecolor hover:rounded-md w-full py-2">
                  {" "}
                  <Link
                    to="/parents"
                    className="flex space-x-5 items-center  px-2"
                  >
                    {" "}
                    <ImUsers className="text-lg " />
                    <p> PARENT</p>
                  </Link>
                </li>
                <li className="  hover:bg-white hover:text-bluecolor hover:rounded-md w-full py-2">
                  <Link
                    to="/preschoolpayment"
                    className="flex space-x-5 items-center  px-2"
                  >
                    {" "}
                    <MdOutlinePayment className="text-lg" />
                    <p> PAYMENT</p>
                  </Link>
                </li>
              </ul>
            </div>
          )}
        </li>

        <li className=" px-2 py-3 text-white  mr-2">
          <button
            className="uppercase flex space-x-5 items-center"
            onClick={toggleDropXpllora}
          >
            <IoPersonAdd className="text-lg" />
            <p>XPLLORA Student</p>
            {xplloraDrop ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}{" "}
          </button>
          {xplloraDrop && (
            <div className="absolutr left-2 top-3 ">
              <ul className=" ml-5 mt-5 flex flex-col space-y-5">
                <li className="  hover:bg-white hover:text-bluecolor hover:rounded-md w-full py-2">
                  <Link
                    to="/addxpllora"
                    className="flex space-x-5 items-center  px-2"
                  >
                    <IoPersonAdd className="text-lg" /> <p> ADD STUDENT</p>
                  </Link>
                </li>
                <li className="  hover:bg-white hover:text-bluecolor hover:rounded-md w-full py-2">
                  <Link
                    to="/allxstudent"
                    className="flex space-x-5 items-center  px-2"
                  >
                    {" "}
                    <ImUsers className="text-lg" />
                    <p> ALL STUDENTS</p>
                  </Link>
                </li>
                <li className="  hover:bg-white hover:text-bluecolor hover:rounded-md w-full py-2">
                  <Link
                    to="/xpllorapayment"
                    className="flex space-x-5 items-center  px-2"
                  >
                    {" "}
                    <MdOutlinePayment className="text-lg" />
                    <p> PAYMENT</p>
                  </Link>
                </li>
              </ul>
            </div>
          )}
        </li>

        {/* {staff ((staff) =>(  */}
        <li className=" px-2 py-3 text-white  mr-2">
          <div className="">
            {role === "admin" && (
              <button
                className="uppercase flex space-x-5 items-center"
                onClick={toggleStaff}
              >
                <IoPersonAdd className="text-lg" />
                <p>NON_ACADEMIC </p>
                {staffDrop ? (
                  <MdKeyboardArrowUp />
                ) : (
                  <MdKeyboardArrowDown />
                )}{" "}
              </button>
            )}
            {staffDrop && (
              <div className="absolutr left-0 top-3 ">
                <ul className="ml-5 mt-5 flex flex-col space-y-5">
                  <li className="  hover:bg-white hover:text-bluecolor hover:rounded-md w-full py-2">
                    <Link
                      to="/addNewstaff"
                      className="flex space-x-5 items-center  px-2"
                    >
                      {" "}
                      <RiParentFill className="text-lg" />
                      <p>ADD STAFF</p>
                    </Link>
                  </li>
                  <li className="  hover:bg-white hover:text-bluecolor hover:rounded-md w-full py-2">
                    <Link
                      to="/allStaff"
                      className="flex space-x-5 items-center  px-2"
                    >
                      <RiParentFill className="text-lg" />
                      <p>ALL STAFF</p>
                    </Link>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </li>
        <li className=" px-2 py-3 text-white  mr-2">
          <div className="">
            {role === "admin" && (
              <button
                className="uppercase flex space-x-5 items-center"
                onClick={toggleAcaemicstaff}
              >
                <IoPersonAdd className="text-lg" />
                <p>ACADEMIC STAFF</p>
                {acaemicstaff ? (
                  <MdKeyboardArrowUp />
                ) : (
                  <MdKeyboardArrowDown />
                )}{" "}
              </button>
            )}
            {acaemicstaff && (
              <div className="absolutr left-0 top-3 ">
                <ul className="ml-5 mt-5 flex flex-col space-y-5">
                  <li className="  hover:bg-white hover:text-bluecolor hover:rounded-md w-full py-2">
                    <Link
                      to="/addacademicstaff"
                      className="flex space-x-5 items-center  px-2"
                    >
                      {" "}
                      <RiParentFill className="text-lg" />
                      <p>ADD STAFF</p>
                    </Link>
                  </li>
                  <li className="  hover:bg-white hover:text-bluecolor hover:rounded-md w-full py-2">
                    <Link
                      to="/allacademicstaff"
                      className="flex space-x-5 items-center  px-2"
                    >
                      <RiParentFill className="text-lg" />
                      <p>ALL STAFF</p>
                    </Link>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </li>
        <li className=" px-2 py-3 text-white  mr-2">
          <div className="">
            {role === "admin" && (
              <button
                className="uppercase flex space-x-5 items-center"
                onClick={toggleCourse}
              >
                <IoSchoolOutline className="text-lg" />
                <p>COURSES</p>
                {courseDrop ? (
                  <MdKeyboardArrowUp />
                ) : (
                  <MdKeyboardArrowDown />
                )}{" "}
              </button>
            )}
            {courseDrop && (
              <div className="absolutr left-0 top-3 ">
                <ul className="ml-5 mt-5 flex flex-col space-y-5">
                  <li className="  hover:bg-white hover:text-bluecolor hover:rounded-md w-full py-2">
                    <Link
                      to="/allpreschollcourse"
                      className="flex space-x-5 items-center  px-2"
                    >
                      {" "}
                      <RiParentFill className="text-lg" />
                      <p>PRE SCHOOL</p>
                    </Link>
                  </li>
                  <li className="  hover:bg-white hover:text-bluecolor hover:rounded-md w-full py-2">
                    <Link
                      to="/courses"
                      className="flex space-x-5 items-center  px-2"
                    >
                      <RiParentFill className="text-lg" />
                      <p>XPLLORA</p>
                    </Link>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </li>

        <li className="px-2   py-3 text-white  hover:bg-white hover:text-bluecolor hover:rounded-md w-full mr-2">
          <button
            className="flex space-x-5 items-center "
            onClick={() => handleLogout()}
          >
            <LuLogOut className="text-lg" />
            <p>LOGOUT</p>
          </button>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
