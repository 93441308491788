import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import Topbar from "../components/Topbar";


import Swal from 'sweetalert2'
import { useNavigate, useParams } from "react-router-dom";
import { addNewExam, clearAddExam, clearUpdateExam, getSingleExam, updateExam } from "../slice/examSlice";

const Updateexam = () => {
    const { single_Exam ,isUpdate} = useSelector(state => state.examState)
    console.log(single_Exam);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {id}= useParams()
    console.log(id);
    
    const [course, setCourse] = useState({
      name: '',
      description: '',
      fees: ''
      
    })

    useEffect(() =>{
        dispatch(getSingleExam(id))
    },[dispatch])

    useEffect(() =>{
        if(Array.isArray(single_Exam.Result) && single_Exam.Result.length >0){
            setCourse({
                name:single_Exam.Result[0].exam_name,
                description:single_Exam.Result[0].exam_description,
                fees:single_Exam.Result[0].fees
            })
        }
    },[single_Exam])
    const [error, setError] = useState({})

  const handleChange = (e) => {
    const { name, value } = e.target
    setCourse({ ...course, [name]: value })
    setError({ ...error, [name]: '' })
  }
  const validate =() =>{
    const error ={}
  let  isValid = true
    if(!course.name){
      error.name="Course Name is Required"
      isValid = false
    }
    if(!course.fees){
      error.fees = "Course Fees is Required"
      isValid = false
    }
    if(!course.description){
      error.description = "Description is Required"
      isValid = false
    }
    setError(error)
    return isValid
     
  }


  const handleSubmit = async (e) => {
    e.preventDefault()
    const isValid = validate()
    if(isValid){
      const courseData = {
        ...course,
       
      }
      console.log(courseData);
      dispatch(updateExam(id,courseData))
    }

  
  }

  useEffect(() => {

    if (isUpdate && isUpdate.status !== undefined) {
      if (isUpdate.status) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Exam Update Successfully!",
          showConfirmButton: false,
          timer: 1500
        });
        dispatch(clearUpdateExam())
        setTimeout(() => {
          navigate('/courses')
        }, 1000)

      } else {
        alert("Error");
      }
    }
  }, [isUpdate]);
  return (
    <div className='h-screen p-3 w-full bg-slate-100'>
    <div className='flex p-2 w-full h-full space-x-5'>
      <div className='w-1/5 h-full bg-bluecolor rounded-xl'><Sidebar /></div>
      <div className='w-4/5'>
        <div className='px-3 bg-white rounded-md py-2'>
          <Topbar name='Add Exam' />
        </div>
        <div className='mt-5 bg-white p-3 rounded-md overflow-auto h-[83vh]'>
          <div className='h-full rounded-md'>
            <form>
              <div className="flex flex-col w-full px-5">
                <p className="font-bold mt-3 text-lg">Add Exam Fees Detail :</p>
                <div className="flex flex-wrap -mx-3 mb-3 mt-3">
                  <div className="w-full px-3 mb-3 md:mb-0">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                      Exam Name
                    </label>
                    <input
                      value={course.name}
                      name="name"
                      onChange={handleChange}
                      className={`appearance-none block w-[75%] bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4  leading-tight focus:outline-none focus:bg-white focus:border-bluecolor
                      ${error.name? "border-red-500" : ''}`}
                      type="text"
                      placeholder="exam Name"
                    />
                    {error.name &&(
                      <p className="text-red-500 text-xs ">{error.name}</p>
                    )}
                  </div>
                  <div className="w-full px-3 mt-2">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                      Description
                    </label>
                    <input
                      value={course.description}
                      name="description"
                      onChange={handleChange}
                      className={`appearance-none block w-[75%] bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-bluecolor
                      ${error.description ? 'border-red-500' : ''}`}
                      type="text"
                      placeholder="Description"
                    />
                    {error.description && (
                      <p className="text-red-500 text-xs">{error.description}</p>
                    )}
                  </div>
                  <div className="w-full px-3">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                      Course Fees
                    </label>
                    <input
                      value={course.fees}
                      name="fees"
                      onChange={handleChange}
                      className={`appearance-none block w-[75%] bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-bluecolor
                      ${error.fees ? 'border-red-500' : ''}` }
                      type="text"
                      placeholder="Fees"
                    />
                    {error.fees && (
                      <p className="text-red-500 text-xs">{error.fees}</p>
                    )}
                  </div>
                </div>
               

                <div className="flex mb-5 py-5 md:justify-end justify-center">
                  <button className="px-5 py-2 text-white bg-bluecolor rounded-md w-56 hover:bg-graycolor text-xl"
                    onClick={(e) => handleSubmit(e)}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Updateexam
