import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import { useDispatch, useSelector } from "react-redux";

import Swal from 'sweetalert2'
import { useNavigate, useParams } from "react-router-dom";
import { addNewActivity, clearActivityCreated, clearActivityUpdate, getSingleExtraActivity, updateExtraActivity } from "../slice/extraActivitySlice";

const Updateextraactivity = () => {
    const {singleActivity,isUpdate} = useSelector((state) => state.extraActivityState)
    console.log(singleActivity);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {id} = useParams()
    console.log(id);
   
   
      const [grade, setGrade] = useState({
          name: "",
          description: "",
          fees: "",
        });
        useEffect(() =>{
            dispatch(getSingleExtraActivity(id))
        },[dispatch])
    
        useEffect(() =>{
            if(Array.isArray(singleActivity.Result) && singleActivity.Result.length > 0 ){
                setGrade({
                    name:singleActivity.Result[0].activity_name,
                    description:singleActivity.Result[0].activity_description,
                    fees:singleActivity.Result[0].fees
                })
            }
        },[singleActivity])
        const [error, setError] = useState({});
      
        const handleChange = (e) => {
          const { name, value } = e.target;
          setGrade({ ...grade, [name]: value });
          setError({ ...error, [name]: "" });
        };
       
      
        const validate = () => {
          let error = {};
          let isValid = true;
      
          if (!grade.name) {
            error.name = "Please enter grade name";
            isValid = false;
          }
          if (!grade.description) {
            error.description = "Please enter description";
            isValid = false;
          }
          if (!grade.fees) {
            error.fees = "Please enter fees";
            isValid = false;
          }
          setError(error);
          return isValid;
        };
      
        const handleSubmit = async (e) => {
          e.preventDefault();
          const isValid = validate();
          if (isValid) {
            const gradeData = {
              ...grade,
             
            };
            console.log(gradeData);
         dispatch(updateExtraActivity(id,gradeData))
          }
        } 
        useEffect(()=>{
          if(isUpdate && isUpdate.status !== undefined){
            if(isUpdate.status){
              Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Activity Update Successfully!",
                showConfirmButton: false,
                timer: 1500
              });
             dispatch(clearActivityUpdate())
              setTimeout(() => {
                navigate('/allpreschollcourse')
              }, 1000)
            }else{
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                footer: '<a href="#">Why do I have this issue?</a>'
              });
            }
          }
          },[isUpdate])
  return (
    <div className=" h-screen p-3 w-full bg-slate-100">
    <div className="flex  p-2 w-full h-full space-x-5">
      <div className="w-1/5 h-full bg-bluecolor rounded-xl ">
        <Sidebar />
      </div>
      <div className=" w-4/5   ">
        <div className="px-3 bg-white rounded-md py-2">
          {/* <p className=' font-bold text-2xl text-graycolor'>Dashboard</p> */}
          <Topbar name="UPDATE PRE SCHOOL EXTRA ACTIVITY
          " />
        </div>

        <div className="  mt-5 bg-white p-3 h-[83vh]  rounded-md ">
          <div className=" h-full  rounded-md">
            <div className="">
              <form>
                <div className="flex flex-col w-full px-10">
                  <p className="font-bold mt-3 text-lg">
                    Add Extra Activity Detail :
                  </p>
                  <div className="flex flex-wrap -mx-3 mb-3 mt-3">
                    <div className="w-full px-3 mb-3 ">
                      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                        Activity Name
                      </label>
                      <input
                        value={grade.name}
                        name="name"
                        onChange={handleChange}
                        className={`appearance-none block w-[75%] bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4  leading-tight focus:outline-none focus:bg-white focus:border-bluecolor
                      ${error.name ? "border-red-500" : ""}`}
                        type="text"
                        placeholder="Course Name"
                      />
                      {error.name && (
                        <p className="text-red-500 text-xs ">{error.name}</p>
                      )}
                    </div>
                    <div className="w-full px-3 mt-2">
                      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                        Description
                      </label>
                      <input
                        value={grade.description}
                        name="description"
                        onChange={handleChange}
                        className={`appearance-none block w-[75%] bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-bluecolor
                      ${error.description ? "border-red-500" : ""}`}
                        type="text"
                        placeholder="Description"
                      />
                      {error.description && (
                        <p className="text-red-500 text-xs">
                          {error.description}
                        </p>
                      )}
                    </div>
                    <div className="w-full px-3">
                      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                        Fees
                      </label>
                      <input
                        value={grade.fees}
                        name="fees"
                        onChange={handleChange}
                        className={`appearance-none block w-[75%] bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-bluecolor
                      ${error.fees ? "border-red-500" : ""}`}
                        type="text"
                        placeholder="Fees"
                      />
                      {error.fees && (
                        <p className="text-red-500 text-xs">{error.fees}</p>
                      )}
                    </div>
                  </div>
                  {/* <div className="flex items-center justify-start space-x-5">
                    <div className="w-3/4 px-1">
                      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                        Grade Levels
                      </label>
                      {gradeLevels.map((grade, index) => (
                        <input
                          key={index}
                          value={grade.grade_level}
                          name="grade_level"
                          onChange={(e) => handleGradeChange(index, e)}
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-bluecolor"
                          type="text"
                          placeholder="Grade Level"
                        />
                      ))}
                    </div>
                    <button
                      type="button"
                      onClick={addGradeLevel}
                      className="px-4 py-2 mt-2 text-white bg-bluecolor rounded-full hover:bg-graycolor"
                    >
                      +
                    </button>
                  </div> */}

                  <div className="flex mb-5 py-5 md:justify-end justify-center">
                    <button
                      className="px-5 py-2 text-white bg-bluecolor rounded-md w-56 hover:bg-graycolor text-xl"
                      onClick={(e) => handleSubmit(e)}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Updateextraactivity
