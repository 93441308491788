import { createSlice } from "@reduxjs/toolkit"
import axios from "axios"
const initialstate = {
    paymentStudent:null,
payment :[],
isPayed:{},
singleStudent:{}
}

const paymentSlice = createSlice({
    name:'payment',
    initialState:initialstate,
    reducers:{
        getStudent(state,action){
          state.paymentStudent=action.payload
        },
        addPayment(state,action){
          state.isPayed=action.payload
        },
        clearPayment(state,action){
          state.isPayed={}
        },
        getAsingleStudent(state,action){
          state.singleStudent=action.payload
        }
    }
})
export const {getStudent,addPayment,clearPayment,getAsingleStudent} = paymentSlice.actions
export default paymentSlice.reducer

export function getPaymetStudent(id) {
    return async function getstudentThunk(dispatch, getState) {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/payment/getstudent/${id}`);
        const result = response.data;
        console.log(result);
        dispatch(getStudent(result));
        return result;
      
      } catch (error) {
        console.error('Error getting student:', error);
        throw error;
      }
    };
  }

  export function  addPaymentDate(Date){
    return async function addPaymentThumk(dispatch,getState){
      try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/payment/addpayment`,Date)
        const result = response.data;
        console.log(result);
        dispatch(addPayment(result))
      } catch (error) {
        console.error('Error adding payment',error)
      }
    }
  }
  export function  getOneStudent(id,month){
    return async function getstudentThumk(dispatch,getState){
      
   
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/payment/getastudent/${id}/${month}`)
        const result = response.data;
        
        dispatch(getAsingleStudent(result))
        console.log(result);
    }
  }
