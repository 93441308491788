import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const initialstate ={
    isCreated :{},
    exam:[],
    single_Exam:[],
    isUpdate:{}
}
const examSlice = createSlice({
    name:"ExamSlice",
initialState:initialstate,
reducers:{
    addExam(state,action){
        state.isCreated = action.payload
    },
    clearAddExam(state,action){
        state.isCreated={}
    },
    allExam(state,action){
        state.exam=action.payload
    },
    singleExam(state,action){
        state.single_Exam=action.payload
    },
    update(state,action){
        state.isUpdate = action.payload
    },
    clearUpdateExam(state,action){
        state.isUpdate={}
    }
}
})

export const {addExam,clearAddExam,allExam,singleExam,update,clearUpdateExam} = examSlice.actions
export default examSlice.reducer

export function addNewExam (ExamData){
    return async function addNewactivityThunk (dispatch,getState) {
        const data = await axios.post(`${process.env.REACT_APP_BASE_URL}/exam/addnew`,ExamData)
        const result =data.data
    dispatch(addExam(result))
        console.log(result);
    }
}

export function getAllExam (){
    return async function getexamThunk (dispatch,getState) {
        const data = await axios.get(`${process.env.REACT_APP_BASE_URL}/exam/getall`)
        const result =data.data
    dispatch(allExam(result))
        console.log(result);
    }
}

export function getSingleExam (id){
    return async function getsingleexamThunk (dispatch,getState) {
        const data = await axios.get(`${process.env.REACT_APP_BASE_URL}/exam/singleexam/${id}`)
        const result =data.data
    dispatch(singleExam(result))
        console.log(result);
    }
}

export function updateExam (id,examData){
    return async function updateexamThunk (dispatch,getState) {
        const data = await axios.put(`${process.env.REACT_APP_BASE_URL}/exam/updateexam/${id}`,examData)
        const result =data.data
    dispatch(update(result))
        console.log(result);
    }
}