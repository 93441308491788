import React, { useEffect } from 'react'
import Sidebar from '../components/Sidebar'
import Topbar from '../components/Topbar'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch ,useSelector} from 'react-redux'
import { getPreschollGrade } from '../slice/preschool_course'
import { MdDelete } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import { AllActivities } from '../slice/extraActivitySlice'

const Allprestudentcourse = () => {
  const { allgrades} = useSelector((state) => state.presclgradeState)
  const {activity} = useSelector((state) => state.extraActivityState)
  console.log(activity);
  const dispatch = useDispatch()
  const navigate = useNavigate()
  useEffect(() =>{
    dispatch(getPreschollGrade())
    console.log(allgrades);
  },[dispatch])

  useEffect(()=>{
    dispatch(AllActivities())
    console.log(activity);
  },[dispatch])
  const renderGradeLevels = (gradeLevels) => {
    try {
      const levels = JSON.parse(gradeLevels);
      if (Array.isArray(levels)) {
        return (
          <ul className="">
            {levels.map((level, index) => (
              <li key={index}>{level.grade_level}</li>
            ))}
          </ul>
        );
      }
      return <li>No levels available</li>;
    } catch (error) {
      console.error("Invalid JSON in gradeLevels:", gradeLevels);
      return <li>Invalid levels data</li>;
    }
  };

  const handleUpdate = (id) =>{
  navigate(`/updatepresclcourse/${id}`)
  }
  const handleExtraUpdate = (id) =>{
    navigate(`/updateactivity/${id}`)
  }
  return (
    <div className=' h-screen p-3 w-full bg-slate-100'>
     
    <div className='flex  p-2 w-full h-full space-x-5' >
    <div className='w-1/5 h-full bg-bluecolor rounded-xl '><Sidebar/></div>
    <div className=' w-4/5   '>
      <div className='px-3 bg-white rounded-md py-2'>
      {/* <p className=' font-bold text-2xl text-graycolor'>Dashboard</p> */}
      <Topbar  name='ALL PRE SCHOOL GRADES' />
      
      </div>
     
     <div className='  mt-5  p-3 h-[83vh]  rounded-md  '>
      <div className=' h-1/2  bg-white rounded-md overflow-auto'>
        <div className=''>
        <Link to='/addpreschool'>
            <button className='bg-bluecolor text-white px-8 mt-5 ml-10 py-2 rounded-md'>ADD GRADE</button>
            </Link>
           </div>
           <div className="border-b-2 border-gray-200 mt-5"></div>
                <div>
                <table className="mt-8 w-full text-left  ">
        <thead>
          <tr className="text-graycolor">
            <th className="px-3 py-3">Course Name</th>
            <th className="px-3 py-3">Description</th>
            <th className="px-3 py-3">Fees</th>
            {/* <th className="px-3 py-3">Levels</th> */}
            <th className="px-3 py-3">Action</th>
          </tr>
        </thead>
        <tbody className="  ">
          {allgrades.map((c) => (
            <tr  className={`mt-3 border-b-[1px] border-b-gray-100 text-bluecolor `}
         >
              <td className="px-3 py-4 ">{c.coursename}</td>
             <td className="px-3 py-4 ">{c.description}</td>
             <td className="px-3 py-4 ">{c.fees}</td>
             {/* <td className="px-3 py-4 ">
             {c.levels
                            ? renderGradeLevels(c.levels)
                            : "No levels available"}
             </td> */}
             <td className="px-3 py-4 flex space-x-2 items-center">
             {/* <button className="font-medium text-xl text-green-600 dark:text-green-500 hover:underline"
        onClick={() => handleView(student.id)}>
            <GrView/>
          </button> */}
          <button
            className="font-medium text-lg text-blue-400  hover:text-blue-500"
           onClick={() => handleUpdate(c.id)}
          >
            <FaEdit />
          </button>
          <button className="font-medium text-lg text-red-400 hover:text-red-500">
            <MdDelete />
          </button>
             </td>
          
            </tr>

          ))}
         
        </tbody>
      </table>
      </div>

     
      </div>
      <div className=' h-1/2 mt-10 bg-white overflow-auto  rounded-md'>
       
      <div className=''>
        <Link to='/extraactiviy'>
            <button className='bg-bluecolor text-white px-8 mt-5 ml-10 py-2 rounded-md'>ADD EXTRA ACTIVITY</button>
            </Link>
           </div>
           <div className="border-b-2 border-gray-200 mt-5"></div>
                <div>
                <table className="mt-8 w-full text-left  ">
        <thead>
          <tr className="text-graycolor">
            <th className="px-3 py-3">Activity Name</th>
            <th className="px-3 py-3">Description</th>
            <th className="px-3 py-3">Fees</th>
            {/* <th className="px-3 py-3">Levels</th> */}
            <th className="px-3 py-3">Action</th>
          </tr>
        </thead>
        <tbody className="  ">
          {activity.map((c) => (
            <tr  className={`mt-3 border-b-[1px] border-b-gray-100 text-bluecolor `}
         >
              <td className="px-3 py-4 ">{c.activity_name}</td>
             <td className="px-3 py-4 ">{c.activity_description}</td>
             <td className="px-3 py-4 ">{c.fees}</td>
             {/* <td className="px-3 py-4 ">
             {c.levels
                            ? renderGradeLevels(c.levels)
                            : "No levels available"}
             </td> */}
             <td className="px-3 py-4 flex space-x-2 items-center">
             {/* <button className="font-medium text-xl text-green-600 dark:text-green-500 hover:underline"
        onClick={() => handleView(student.id)}>
            <GrView/>
          </button> */}
          <button
            className="font-medium text-lg text-blue-400  hover:text-blue-500"
           onClick={() => handleExtraUpdate(c.activity_id)}
          >
            <FaEdit />
          </button>
          <button className="font-medium text-lg text-red-400 hover:text-red-500">
            <MdDelete />
          </button>
             </td>
          
            </tr>

          ))}
         
        </tbody>
      </table>
      </div>

     
      </div>
      </div>
    </div>
    </div>
    </div>
  )
}

export default Allprestudentcourse
